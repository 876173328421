import React, { useEffect, useState } from 'react'
import './style.css'
import { Instagram, Telegram, WhatsApp } from '@mui/icons-material'

const SocialMediaStickyIcons: React.FC<any> = (props: {
    whatsappLink: string
    telegramLink: string
    instagramLink: string
}) => {
    const [isBouncing, setIsBouncing] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            setIsBouncing(true)
            setTimeout(() => setIsBouncing(false), 1000)
        }, 2000)

        return () => clearInterval(interval)
    }, [])

    return (
        <div className='social-sticky-icons-container'>
            <div
                className={`social-sticky-icons-whatsapp icon-container ${isBouncing ? 'bounce' : ''}`}
            >
                {props.whatsappLink && (
                    <a
                        aria-label='whatsapp link'
                        href={
                            props.whatsappLink && props.whatsappLink.startsWith('http')
                                ? props.whatsappLink
                                : `https://wa.me/${props.whatsappLink}`
                        }
                        target='_blank'
                        rel='noopener noreferrer'
                        className='cursor-pointer icon whatsapp'
                    >
                        <WhatsApp style={{ fontSize: '2rem' }} />
                    </a>
                )}
            </div>
            <div
                className={`social-sticky-icons-telegram icon-container ${isBouncing ? 'bounce' : ''}`}
            >
                {props.telegramLink && (
                    <a
                        aria-label='telegram link'
                        href={
                            props.telegramLink && props.telegramLink.startsWith('http')
                                ? props.telegramLink
                                : `https://t.me/${props.telegramLink}`
                        }
                        target='_blank'
                        rel='noopener noreferrer'
                        className='cursor-pointer icon telegram'
                    >
                        <Telegram style={{ fontSize: '2rem' }} />
                    </a>
                )}
            </div>
            <div
                className={`social-sticky-icons-instagram icon-container ${isBouncing ? 'bounce' : ''}`}
            >
                {props.instagramLink && (
                    <a
                        aria-label='telegram link'
                        href={
                            props.instagramLink
                        }
                        target='_blank'
                        rel='noopener noreferrer'
                        className='cursor-pointer icon telegram'
                    >
                        <Instagram style={{ fontSize: '2rem' }} />
                    </a>
                )}
            </div>
        </div>
    )
}

export default SocialMediaStickyIcons
