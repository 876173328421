import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { getAssetUrl } from '../utils'
import { DrawerComp } from '../pages/admin/events/Content/component/DrawerComp'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import { OPEN_LOGIN_SCREEN } from '../redux/actions/userAction/betSlipAction'

interface MobileNavBarProps {
  userData: any
  isLogin: any
}

const MobileNavBar = (props: MobileNavBarProps) => {
  const data = [
    {
      icon: (
        <HomeOutlinedIcon className='w-[30px] h-[30px] text-[#fff] !text-[25px] !fill-white' />
      ),
      name: 'Home',
      path: '/',
    },
    {
      icon: <img src={getAssetUrl('header/casinoChip.webp')} />,
      name: 'Top Gmes',
      path: '/top-casino',
    },
    {
      icon: (
        <IoDocumentTextOutline style={{ fontSize: '24px', color: '#ffffff' }} />
      ),
      name: 'Bet',
      path: '/',
    },
    {
      icon: <img src={getAssetUrl('header/chip.webp')} />,

      name: 'Live Casnio',
      path: '/live-casino',
    },
  ]

  const [activeMobileTab, setActiveMobileTab] = useState<any>(() => {
    const savedTab = localStorage.getItem('mobileActiveTab');
    return savedTab ? JSON.parse(savedTab) : 0;
});

  const setActiveTab = (id: any) => {
    setActiveMobileTab(id)
    localStorage.setItem('mobileActiveTab', id)
  }

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }
  return (
    <>
      <div
        className={` flex px-5 py-2 justify-between h-[55px]  header_gradient ${isDrawerOpen ? 'hidden' : ''} `}
      >
        {data.map((item, i) => (
          <CustomLink
            path={item.path}
            icon={item.icon}
            text={item.name}
            isActive={i === activeMobileTab}
            onClick={setActiveTab}
            index={i}
            setIsDrawerOpen={toggleDrawer}
            isLogin={props.isLogin}
          />
        ))}
      </div>
      <DrawerComp
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        userData={props.userData}
      />
    </>
  )
}

interface CustomLinkProps {
  path: string
  icon: React.ReactNode
  text: string
  isActive: boolean
  onClick: any
  index: any
  setIsDrawerOpen: any
  isLogin: boolean
}

const CustomLink: React.FC<CustomLinkProps> = ({
  path,
  isActive,
  icon,
  text,
  onClick,
  index,
  setIsDrawerOpen,
  isLogin,
}) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (text === 'Bet' && !isLogin) {
      dispatch({
        type: OPEN_LOGIN_SCREEN,
        openLoginScreen: true,
      })
      return
    } else if (text === 'Bet' && isLogin) {
      setIsDrawerOpen()
      onClick(index)
    }
  }

  return (
    <>
      {text === 'Bet' ? (
        <div
          onClick={() => {
            handleClick()
          }}
          className='cursor-pointer w-[calc(100%/4)]'
        >
          <div
            className={`flex items-center justify-center ${isActive ? '' : ''} flex-col gap-[0.5px] relative shrink-0  `}
          >
            <div className='h-[25px]'>
              <div
                className={` rounded-full    ${isActive ? ' p-4 -translate-y-[40px] relative top border-[5px] border-[white] bg-gradient-to-r from-[var(--inPlay-match-gradientFrom)] to-[var(--inPlay-match-gradientTo)] ' : ''}`}
              >
                {icon}
              </div>
            </div>
            <div>
              <p
                className={`text-[#fff] text-[15px]  ${isActive ? '!text-[14px] font-semibold' : ''}`}
              >
                {text}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <Link
          to={path}
          onClick={() => {
            if (text === 'Bet') {
              setIsDrawerOpen()
              onClick(index)
            } else {
              onClick(index)
            }
          }}
          className='cursor-pointer w-[calc(100%/4)]'
        >
          <div
            className={`flex items-center justify-center ${isActive ? '' : ''} flex-col gap-[0.5px] relative shrink-0  `}
          >
            <div className='h-[25px]'>
              <div
                className={` rounded-full    ${isActive ? ' p-4 -translate-y-[40px] relative top border-[5px] border-[white] bg-gradient-to-r from-[var(--inPlay-match-gradientFrom)] to-[var(--inPlay-match-gradientTo)] ' : ''}`}
              >
                {icon}
              </div>
            </div>
            <div>
              <p
                className={`text-[#fff] text-[15px]  ${isActive ? '!text-[14px] font-semibold' : ''}`}
              >
                {text}
              </p>
            </div>
          </div>
        </Link>
      )}
    </>
  )
}

export default MobileNavBar
